<template>
  <b-sidebar
    id="serv-all-detail"
    sidebar-class="sidebar-xl"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h4 class="mb-0 font-weight-bolder">서버 상세</h4>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Header End -->
      <!-- Body -->
      <!-- 서버 상세 리스트 -->
      <b-table
        ref="refInvoiceListTable"
        :items="servAllDetailItem"
        responsive
        :fields="servAllDetailColums"
        primary-key="ip"
        show-empty
        empty-text="조회된 목록이 없습니다."
        class="custom position-relative table-setting text-left mb-0"
      >
        <!-- <template slot="thead-top">
                <tr class="text-center">
                  <th colspan="2">IP ({{ detailItems.ip }})</th>
                </tr>
              </template> -->
      </b-table>
      <b-table
        ref="refInvoiceListTable"
        :items="servAllDetailItem2"
        responsive
        :fields="servAllDetailColums2"
        primary-key="ip"
        show-empty
        empty-text="조회된 목록이 없습니다."
        class="custom position-relative table-setting text-left mb-0"
      >
      </b-table>
      <b-table
        ref="refInvoiceListTable"
        :items="servAllDetailItem3"
        responsive
        :fields="servAllDetailColums3"
        primary-key="ip"
        show-empty
        empty-text="조회된 목록이 없습니다."
        class="custom position-relative table-setting text-left mb-0"
      >
      </b-table>
      <!-- 서버 상세 리스트 END -->
      <!-- Body End -->
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BButton, BTable, VBToggle } from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import Ripple from "vue-ripple-directive";

const qs = require("qs");

export default {
  components: {
    BSidebar,
    BForm,
    BButton,
    BTable,
  },
  props: {
    detailItems: {
      type: Object,
      default: () => [],
    },
    codecItem: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      servAllDetailItem: [
        {
          ce_ver: "Status",
          commend: "",
        },
      ],
      servAllDetailItem2: [
        {
          ce_ver: "Slot ID",
          commend: "",
        },
        {
          ce_ver: "Chassis ID",
          commend: "",
        },
        {
          ce_ver: "Vendor",
          commend: "",
        },
        {
          ce_ver: "PID",
          commend: "",
        },
        {
          ce_ver: "Revision",
          commend: "",
        },
        {
          ce_ver: "Serial",
          commend: "",
        },
        {
          ce_ver: "Manufacturing Date",
          commend: "",
        },
        {
          ce_ver: "Asset Tag",
          commend: "",
        },
        {
          ce_ver: "Unique Identifier",
          commend: "",
        },
        {
          ce_ver: "Service Profile",
          commend: "",
        },
        {
          ce_ver: "Health LED",
          commend: "",
        },
      ],
      servAllDetailItem3: [
        {
          ce_ver: "Cores Enabled",
          commend: "",
        },
        {
          ce_ver: "Cores",
          commend: "",
        },
        {
          ce_ver: "Threads",
          commend: "",
        },
        {
          ce_ver: "Effective Memory (MB)",
          commend: "",
        },
        {
          ce_ver: "Total Memory (MB)",
          commend: "",
        },
        {
          ce_ver: "Operating Memory Speed",
          commend: "",
        },
        {
          ce_ver: "Operating Memory Voltage",
          commend: "",
        },
        {
          ce_ver: "Adapters",
          commend: "",
        },
        {
          ce_ver: "NICs",
          commend: "",
        },
        {
          ce_ver: "HBAs",
          commend: "",
        },
        {
          ce_ver: "Original UUID",
          commend: "",
        },
      ],
    };
  },
  mounted() {},
  watch: {
    detailItems(data) {
      if (this.$route.query.equipCode === "EQC002") {
        this.servAllDetailItem = [
          {
            ce_ver: "Status",
            commend: data.operState,
          },
        ];

        this.servAllDetailItem2 = [
          {
            ce_ver: "Slot ID",
            commend: data.slotId,
          },
          {
            ce_ver: "Chassis ID",
            commend: data.chassisId,
          },
          {
            ce_ver: "Vendor",
            commend: data.vendor,
          },
          {
            ce_ver: "PID",
            commend: data.model,
          },
          {
            ce_ver: "Revision",
            commend: data.revision,
          },
          {
            ce_ver: "Serial",
            commend: data.serial,
          },
          {
            ce_ver: "Manufacturing Date",
            commend: data.mfgTime.substr(0, 10),
          },
          {
            ce_ver: "Asset Tag",
            commend: data.assetTag,
          },
          {
            ce_ver: "Unique Identifier",
            commend: data.uuid,
          },
          {
            ce_ver: "Service Profile",
            commend: data.assignedToDn,
          },
          // {
          //   'ce_ver': 'Health LED',
          //   'commend': data.model,
          // },
        ];
        this.servAllDetailItem3 = [
          {
            ce_ver: "Number of Processors",
            commend: data.numOfCpus,
          },
          {
            ce_ver: "Cores Enabled",
            commend: data.numOfCoresEnabled,
          },
          {
            ce_ver: "Cores",
            commend: data.numOfCores,
          },
          {
            ce_ver: "Threads",
            commend: data.numOfThreads,
          },
          {
            ce_ver: "Effective Memory (MB)",
            commend: data.availableMemory,
          },
          {
            ce_ver: "Total Memory (MB)",
            commend: data.totalMemory,
          },
          {
            ce_ver: "Operating Memory Speed",
            commend: data.memorySpeed,
          },
          {
            ce_ver: "Operating Memory Voltage",
            commend: data.lowVoltageMemory,
          },
          {
            ce_ver: "Adapters",
            commend: data.numOfAdaptors,
          },
          {
            ce_ver: "NICs",
            commend: data.numOfEthHostIfs,
          },
          {
            ce_ver: "HBAs",
            commend: data.numOfFcHostIfs,
          },
          {
            ce_ver: "Original UUID",
            commend: data.originalUuid,
          },
        ];
      } else {
        this.servAllDetailItem = [
          {
            ce_ver: "Status",
            commend: data.biosPostState,
          },
        ];

        this.servAllDetailItem2 = [
          {
            ce_ver: "Server ID",
            commend: data.serverId,
          },
          {
            ce_ver: "Class ID",
            commend: data.ClassId,
          },
          {
            ce_ver: "Vendor",
            commend: data.vendor,
          },
          {
            ce_ver: "Model",
            commend: data.model,
          },
          {
            ce_ver: "Serial",
            commend: data.serial,
          },
          {
            ce_ver: "Asset Tag",
            commend: data.assetTag,
          },
          {
            ce_ver: "Unique Identifier",
            commend: data.uuid,
          },
          {
            ce_ver: "Service Profile",
            commend: data.dn,
          },
        ];
        this.servAllDetailItem3 = [
          {
            ce_ver: "Number of Processors",
            commend: data.numOfCpus,
          },
          {
            ce_ver: "Cores Enabled",
            commend: data.numOfCoresEnabled,
          },
          {
            ce_ver: "Cores",
            commend: data.numOfCores,
          },
          {
            ce_ver: "Threads",
            commend: data.numOfThreads,
          },
          {
            ce_ver: "Effective Memory (MB)",
            commend: data.availableMemory,
          },
          {
            ce_ver: "Total Memory (MB)",
            commend: data.totalMemory,
          },
          {
            ce_ver: "Operating Memory Speed",
            commend: data.memorySpeed,
          },
          {
            ce_ver: "Adapters",
            commend: data.numOfAdaptors,
          },
          {
            ce_ver: "NICs",
            commend: data.numOfEthHostIfs,
          },
          {
            ce_ver: "HBAs",
            commend: data.numOfFcHostIfs,
          },
          {
            ce_ver: "Original UUID",
            commend: data.originalUuid,
          },
        ];
      }
    },
  },
  setup() {
    const refInvoiceListTable = ref(null);
    const perPage = ref(10);
    const totalInvoices = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref("ip");
    const isSortDirDesc = ref(true);

    // const cdStuSettingColums = [
    //     { key: 'ce_ver', label: '초기설정값이 아래와 같이 변경됩니다.'},
    //     { key: 'commend', label: ''},
    // ]
    const servAllDetailColums = [
      { key: "ce_ver", label: "Status" },
      { key: "commend", label: "" },
    ];

    const servAllDetailColums2 = [
      { key: "ce_ver", label: "Properties" },
      { key: "commend", label: "" },
    ];

    const servAllDetailColums3 = [
      { key: "ce_ver", label: "Summary" },
      { key: "commend", label: "" },
    ];

    const resolveInvoiceStatusVariantAndIcon = (status) => {
      if (status === "Partial Payment")
        return { variant: "warning", icon: "PieChartIcon" };
      if (status === "On")
        return { variant: "success", icon: "CheckCircleIcon" };
      if (status === "On3")
        return { variant: "info", icon: "ArrowDownCircleIcon" };
      if (status === "Draft") return { variant: "primary", icon: "SaveIcon" };
      if (status === "Sent") return { variant: "secondary", icon: "SendIcon" };
      if (status === "Past Due") return { variant: "danger", icon: "InfoIcon" };
      if (status === "On2")
        return { variant: "primary", icon: "CheckCircleIcon" };
      if (status === "") return { variant: "danger", icon: "InfoIcon" };
      return { variant: "secondary", icon: "XIcon" };
    };
    const resolveClientAvatarVariant = (status) => {
      if (status === "예약일자") return "primary";
      if (status === "예약시작") return "danger";
      if (status === "예약종료") return "secondary";
      if (status === "Draft") return "warning";
      if (status === "Sent") return "info";
      if (status === "Past Due") return "success";
      return "primary";
    };

    return {
      // cdStuSettingCeItem,
      servAllDetailColums,
      servAllDetailColums2,
      servAllDetailColums3,
      // cdStuSettingColums,
      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    };
  },
  methods: {},
};
</script>
<template>
  <section id="dashboard-main" class="mt-2">
    <b-row class="match-height">
      <!-- 전체 서버 목록 리스트 -->
      <b-col xl="12" lg="12" md="12" class="ecs-card">
        <!-- Table Container Card -->
        <b-card no-body>
          <div class="mr-1 ml-1 mt-1 mb-0">
            <b-row>
              <!-- 타이틀 -->
              <b-col cols="12" xl="12" lg="12" md="12" class="clearfix mb-2">
                <h4 class="float-left font-weight-bolder mt-8px">
                  전체 서버 목록
                </h4>
                <!-- <div class="justify-content-center spinner-custom float-right ml-1" :class="{ 'd-none' : active }">
                    <b-spinner
                      v-for="variant in variants"
                      :variant="variant"
                      :key="variant"
                    ></b-spinner>
                </div> -->
                <!-- <b-form-select
                    class="col-md-2 float-right"
                    v-model="selectArea"
                    size=""
                    :options="this.areaOption"
                    placeholder="UCS List"
                    @change="setFilter(selectArea)"
                  /> -->
              </b-col>
              <!-- 타이틀 END -->

              <!-- 탭 메뉴 -->
              <!-- <b-col
                cols="12"
                md="12"
              > 
                <b-tabs class="custom">
                  <b-tab title="All" @click="clickTab('all')">
                  </b-tab>
                  <b-tab title="info" @click="clickTab('info')">
                  </b-tab>
                  <b-tab title="Critical" @click="clickTab('critical')">
                  </b-tab>
                  <b-tab title="Major" @click="clickTab('major')">
                  </b-tab>
                  <b-tab title="Minor" @click="clickTab('minor')">
                  </b-tab>
                  <b-tab title="Warning" @click="clickTab('warning')">
                  </b-tab>
                </b-tabs>
              </b-col> -->
              <!-- 검색 박스 / 버튼 -->
            </b-row>
          </div>
          <div class="">
            <b-row class="mr-1 ml-1 justify-content-end">
              <b-col md="2" class=""> </b-col>
            </b-row>
          </div>
          <!-- <div class="postion-relative text-center table-noview table-responsive">
            <table class=""></table>      
          </div> -->
          <div class="pl-1 pr-1">
            <vue-good-table
              :columns="ServObNtColumns"
              :rows="this.items"
              :rtl="direction"
              v-dragscroll="{ target: '.vgt-responsive' }"
              :search-options="{
                enabled: false,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              theme="my-theme"
              class="table good-table-custom table-light"
              @on-row-click="onRowClick"
            >
              <div slot="emptystate" class="text-center">
                <span v-if="active === true">조회된 목록이 없습니다.</span>
                <span :class="{ 'd-none': active }">
                  <b-skeleton animation="wave" width="100%"></b-skeleton>
                  <b-skeleton animation="wave" width="85%"></b-skeleton>
                  <b-skeleton animation="wave" width="60%"></b-skeleton>
                  <b-skeleton animation="wave" width="93%"></b-skeleton>
                  <b-skeleton animation="wave" width="45%"></b-skeleton>
                </span>
              </div>
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <div v-if="props.column.field === 'num'" class="text-nowrap">
                  {{ props.row.originalIndex + 1 }}
                </div>
                <div v-if="props.column.field === 'name'" class="text-nowrap">
                  {{ props.formattedRow.name }}
                </div>
                <div
                  v-if="props.column.field === 'equipCode'"
                  class="text-nowrap"
                >
                  <span v-if="props.formattedRow.equipCode == 'EQC001'">
                    CIMC 장비
                  </span>
                  <span v-if="props.formattedRow.equipCode == 'EQC002'">
                    UCS 장비
                  </span>
                </div>
                <div
                  v-if="props.column.field === 'fgroupNm'"
                  class="text-nowrap"
                >
                  {{ props.formattedRow.fgroupNm }}
                </div>
                <div
                  v-if="props.column.field === 'sgroupNm'"
                  class="text-nowrap"
                >
                  {{ props.formattedRow.sgroupNm }}
                </div>
                <div
                  v-if="props.column.field === 'hardwareNm'"
                  class="text-nowrap"
                >
                  {{ props.formattedRow.hardwareNm }}
                </div>
                <div v-if="props.column.field === 'sn'" class="text-nowrap">
                  {{ props.formattedRow.sn }}
                </div>
                <div v-if="props.column.field === 'hostNm'" class="text-nowrap">
                  {{ props.formattedRow.hostNm }}
                </div>
                <div v-if="props.column.field === 'ipBody'" class="text-nowrap">
                  {{ props.formattedRow.ipBody }}
                </div>
                <div
                  v-if="props.column.field === 'groupIp'"
                  class="text-nowrap"
                >
                  {{ props.formattedRow.groupIp }}
                </div>
                <div
                  v-if="props.column.field === 'installAt'"
                  class="text-nowrap"
                >
                  {{ props.formattedRow.installAt }}
                </div>
                <div
                  v-if="props.column.field === 'criticalCnt'"
                  class="text-nowrap"
                >
                  <b-skeleton
                    type="avatar"
                    height="30px"
                    width="30px"
                    style="margin: 0 auto"
                    v-if="active == false"
                  ></b-skeleton>
                  <span v-else>{{ props.formattedRow.criticalCnt }}</span>
                </div>
                <div
                  v-if="props.column.field === 'majorCnt'"
                  class="text-nowrap"
                >
                  <b-skeleton
                    type="avatar"
                    height="30px"
                    width="30px"
                    style="margin: 0 auto"
                    v-if="active == false"
                  ></b-skeleton>
                  <span v-else>{{ props.formattedRow.majorCnt }}</span>
                </div>
                <div
                  v-if="props.column.field === 'minorCnt'"
                  class="text-nowrap"
                >
                  <b-skeleton
                    type="avatar"
                    height="30px"
                    width="30px"
                    style="margin: 0 auto"
                    v-if="active == false"
                  ></b-skeleton>
                  <span v-else>{{ props.formattedRow.minorCnt }}</span>
                </div>
                <!-- <div
                    v-if="props.column.field === 'setting'"
                    class="text-nowrap"
                    >
                    <a
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-toggle.serv-all-detail
                      size="sm"
                      @click="detailProps(items[props.row.originalIndex])"
                      >
                      <button
                      style="border: 1px solid #eee; border-radius: 10px; padding: 8px; font-size : 10px;"
                      size="sm"
                      >
                      상세
                      </button>
                    </a>
                  </div> -->
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-end flex-wrap">
                  <div>
                    <b-pagination
                      :value="1"
                      v-model="currentPageNo"
                      :total-rows="totalRecordCount"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="m-2"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
            <serv-all-detail :detailItems="this.detailItem"></serv-all-detail>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<style>
.badge-critical {
  background-color: #ea5455 !important;
}
.badge-major {
  background-color: #ff9f43 !important;
}
.badge-minor {
  background-color: #ffd500 !important;
}
.badge-warning {
  background-color: #00967e !important;
}
.badge-info {
  background-color: #616161 !important;
}
</style>
<script>
// import ucsData from '@/assets/api/ucs.ts'
import Ripple from "vue-ripple-directive";

import {
  BCard,
  BRow,
  BCol,
  BTable,
  BFormSelect,
  BButton,
  VBToggle,
  BSpinner,
  BPagination,
  BTabs,
  BTab,
  BBadge,
  BFormInput,
  BFormDatepicker,
  BSkeleton,
} from "bootstrap-vue";

import store from "@/store/index";
import { dragscroll } from "vue-dragscroll";
import { VueGoodTable } from "vue-good-table";

import ServAllDetail from "./components/ServAllDetail.vue";

const qs = require("qs");

export default {
  directives: {
    Ripple,
    dragscroll,
    "b-toggle": VBToggle,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BSpinner,
    BTable,
    // BAvatar,
    VueGoodTable,
    BFormSelect,
    BButton,
    BPagination,
    BTabs,
    BTab,
    BBadge,
    BFormDatepicker,
    BSkeleton,
    ServAllDetail,
  },
  data() {
    return {
      data: {
        items: [],
        totalInvoices: 0,
      },
      pageLength: 10,
      searchTerm: "",
      variants: ["primary"],
      active: true,
      ucsUrl: "ucsapi/nuova",
      perPage: 10,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      itemLength: 0,
      selectArea: "",
      areaOption: [{ text: "전체", value: "" }],
      detailItem: {},
      ServObNtColumns: [
        {
          field: "num",
          label: "번호",
          sortable: true,
          sortFn: this.sortFn,
          width: "60px",
        },
        {
          field: "name",
          label: "그룹망 이름",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "그룹망 이름",
          },
        },
        {
          field: "groupIp",
          label: "그룹망 IP",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "그룹망 IP",
          },
        },

        {
          field: "criticalCnt",
          label: "Critical",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "Critical",
          },
        },
        {
          field: "majorCnt",
          label: "Major",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "Major",
          },
        },
        {
          field: "minorCnt",
          label: "Minor",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "Minor",
          },
        },
        {
          field: "equipCode",
          label: "장비 분류 코드",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "장비 분류 코드",
          },
        },
        {
          field: "fgroupNm",
          label: "대구분",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "대구분",
          },
        },
        {
          field: "sgroupNm",
          label: "구분",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "구분",
          },
        },
        {
          field: "hardwareNm",
          label: "H/W",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "H/W",
          },
        },
        {
          field: "sn",
          label: "S/N",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "S/N",
          },
        },
        {
          field: "hostNm",
          label: "Host name",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "Host name",
          },
        },
        {
          field: "ipBody",
          label: "IP 내용",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "IP 내용",
          },
        },
        {
          field: "installAt",
          label: "설치완료여부",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "설치완료여부",
          },
        },
        //   { field: 'setting', label: '상세'},
      ],
      items: [],
      resultList: [],
      totalList: [],
      totalPageCount: 0,
      currentPageNo: 1,
      totalRecordCount: 0,
      getApiStus: false,
    };
  },
  watch: {
    currentPageNo() {
      this.getServAll();
    },
  },
  created() {
    this.items = this.$store.state.ucsServerList;
    // console.log(this.items)
  },
  mounted() {
    // console.log(this.$store.state.ucsServerList)
    // 상세 페이지에서 뒤돌아 왔을 시 stroe에 있는 list로 set
    if (!this.$route.query.stus) {
      this.getServAll();
      this.getServUcsAll();
    } else {
      // this.items = this.$store.state.ucsServerList
    }
  },
  methods: {
    /**
     * 전체 권역 fault count 조회
     * @create 2021-04-14
     * @author 정한슬
     * @returns {json}
     * @description 전체 권역 fault count 조회
     */
    getServAll() {
      this.active = false;
      // let param = {
      //   pageIndex : this.currentPageNo,
      // }
      this.$nBack
        .post("ucsapi/servFault")
        .then((response) => {
          // console.log(response)
          if (response.data.resultCode == "success") {
            // console.log(response.data.resultCode)
            this.$store.commit("updateAllServList", response.data);
            // this.items = this.$store.state.servList.resultList
            // console.log(this.$store.state.servList, this.items)
            // // loading
            for (let t = 0; this.items.length > t; t++) {
              // console.log(this.items[t])
              for (let s = 0; this.$store.state.servList.data.length > s; s++) {
                // console.log(this.items[t].sn == this.$store.state.servList.data[s].tbUcsMasterSn)
                if (
                  this.items[t].sn ==
                  this.$store.state.servList.data[s].tbUcsMasterSn
                ) {
                  // tbUcsMasterSn 으로 구분
                  if (
                    this.$store.state.servList.data[s].xmlGb == "UCSGBS0002"
                  ) {
                    // Critical
                    this.items[t].criticalCnt =
                      this.$store.state.servList.data[s].cnt;
                  } else if (
                    this.$store.state.servList.data[s].xmlGb == "UCSGBS0003"
                  ) {
                    // Major
                    this.items[t].majorCnt =
                      this.$store.state.servList.data[s].cnt;
                  } else if (
                    this.$store.state.servList.data[s].xmlGb == "UCSGBS0004"
                  ) {
                    // Minor
                    this.items[t].minorCnt =
                      this.$store.state.servList.data[s].cnt;
                  }
                }
              }
            }
            this.active = true;
            // console.log(this.items)
            // // pagenation
            // this.totalPageCount = this.$store.state.servList.paginationInfo.totalPageCount
            // this.totalRecordCount = this.$store.state.servList.paginationInfo.totalRecordCount
          } else {
            this.$store.commit("updateAllServList", []);
          }
        })
        .catch((error) => {
          this.$store.commit("updateAllServList", []);
          this.active = true;
        });
    },
    /**
     * 전체 권역에 대한 전체 서버 목록 조회
     * @create 2021-04-14
     * @author 정한슬
     * @returns {json}
     * @description 전체 권역에 대한 전체 서버 목록 조회
     */
    getServUcsAll() {
      this.$store.commit("updateUcsAllServListStus", false);
      this.$nBack
        .post(this.ucsUrl, {
          anuova: 5,
          apiGb: "all",
        })
        .then((response) => {
          this.$store.commit("updateUcsAllServListStus", true);
          if (response.data.resultCode == "success") {
            this.$store.commit("updateUcsAllServList", response.data.data);
          } else {
            this.$store.commit("updateUcsAllServList", []);
          }
          this.active = true;
        })
        .catch((error) => {
          this.active = true;
        });
    },
    /**
     * 권역에 대한 서버 페이지로 이동
     * @create 2021-04-14
     * @author 정한슬
     * @returns {json}
     * @description 권역에 대한 서버 페이지로 이동
     */
    onRowClick(data) {
      this.$router.push({
        name: "ucs-servucsall",
        query: { ip: data.row.groupIp, equipCode: data.row.equipCode },
        // equipCode: 
      });
    },
  },
  computed: {
    rows() {
      return this.items.length;
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
};
</script>

<style lang="scss" scoped>
th,
td {
  text-align: center;
  white-space: nowrap;
}
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
